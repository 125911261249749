<template>
    <div class="contenedor-orden-dia">
        <div class="orden-titulo">
            <h1>Sesión Ordinaria 41º</h1>
        </div>
        <div class="logos"></div>
        <div class="orden-body">
            <h3>CUADRAGÉSIMA PRIEMRA SESIÓN ORDINARIA DEL SISTEMA ESTATAL PARA PREVENIR, ATENDER, SANCIONAR Y ERADICAR LA VIOLENCIA CONTRA LAS MUJERES</h3>
            <p>A TRAVES DE LA PLATAFORMA VIRTUAL ZOOM, CUERNAVACA, MORELOS</p>
            <p>INICIO: <b>12:30 HORAS</b></p>
            <h3>ORDEN DEL DÍA</h3>
            <ol>
                <li>BIENVENIDA A LAS Y LOS INTEGRANTES E INVITADOS DEL SISTEMA, DE LA SOCIEDAD CIVIL Y/O ACADEMIA, ASÍ COMO AUTORIDADES QUE ACOMPAÑAN, POR PARTE DEL <b>LICENCIADO PABLO OJEDA CÁRDENAS</b>, SECRETARIO DE GOBIERNO Y PRESIDENTE DEL SEPASE <b>(1 MINS)</b> </li>
                <li>PASE DE LISTA Y DECLARACIÓN DE QUÓRUM LEGAL, POR PARTE DE LA <b>ARQUITECTA FLOR DESSIRÉ LEÓN HERNÁNDEZ</b>, PRESIDENTA DEL INSTITUTO DE LA MUJER PARA EL ESTADO DE MORELOS Y SECRETARIA EJECUTIVA DEL SEPASE <b>(2 MINS)</b></li>
                <li>LECTURA Y EN SU CASO APROBACIÓN DEL ORDEN DEL DÍA, POR PARTE DE LA <b>ARQUITECTA FLOR DESSIRÉ LEÓN HERNÁNDEZ</b>, PRESIDENTA DEL INSTITUTO DE LA MUJER PARA EL ESTADO DE MORELOS Y SECRETARIA EJECUTIVA DEL SEPASE <b>(2 MINS)</b> <a href="https://drive.google.com/file/d/1-Zt0Zo2eImNYunpRukKMgC6eDXoeT7V2/view?usp=sharing" target="_blink">- Mostrar documento</a></li>
                <li>DISPENSA, APROBACIÓN Y FIRMA DEL ACTA CORRESPONDIENTE A LA CUADRAGÉSIMA SESIÓN ORDINARIA Y QUINTA SESIÓN EXTRAORDINARIA, POR PARTE DE LA <b>ARQUITECTA FLOR DESSIRÉ LEÓN HERNÁNDEZ</b>, PRESIDENTA DEL INSTITUTO DE LA MUJER PARA EL ESTADO DE MORELOS Y SECRETARIA EJECUTIVA DEL SEPASE <b>(5 MINS)</b> <a href="https://drive.google.com/file/d/1B2i92nDbvGmjWHKcrT_mEqNvmNUw4Yfr/view?usp=sharing" target="_blank"> - Mostrar documento (40º sesión ordinaria)</a> <a href="https://drive.google.com/file/d/1wPXbNAvjoL1oN9bvfZMNvPUfRRf0kUYu/view?usp=sharing" target="_blink">- Mostrar documento (5ta sesión extraordinaria)</a></li>
                <li>SEGUIMIENTO DE LOS ACUERDOS EMANADOS DE LAS SESIONES ORDINARIAS DEL SISTEMA ESTATAL PARA PREVENIR, ATENDER, SANCIONAR Y ERRADICAR LA VIOLENCIA CONTRA LAS MUJERES, POR PARTE DE LA <b>ARQUITECTA FLOR DESSIRÉ LEÓN HERNÁNDEZ</b>, PRESIDENTA DEL INSTITUTO DE LA MUJER PARA EL ESTADO DE MORELOS Y SECRETARIA EJECUTIVA DEL SEPASE <b> (5 MINS)</b> <a href="https://drive.google.com/file/d/1M5ls6Re8bqCQH1wun1PsHgbVTDsomY2s/view?usp=sharing" target="_blank">- Mostrar documento</a></li>
                <li>PRESENTACIÓN Y EN SU CASO APROBACIÓN DEL CALENDARIO ANUAL DE SESIONES ORDINARIAS DEL SISTEMA ESTATAL PARA PREVENIR, ATENDER, SANSIONAR Y ERRADICAR LA VIOLENCIA CONTRA LAS MUJERES, POR PARTE DE LA <b>ARQUITECTA FLOR DESSIRÉ LEÓN HERNÁNDEZ</b>, PRESIDENTA DEL INSTITUTO DE LA MUJER PARA EL ESTADO DE MORELOS Y SECRETARIA EJECUTIVA DEL SEPASE <b> (2 MINS)</b><a href="https://drive.google.com/file/d/1k-_9KNjh_ixfy9k7l2phfhHK-XBvrht2/view?usp=sharing" target="_blank">- Mostrar documento</a></li>
                <li>INFORME ACERCA DE LA ELABORACIÓN DE LOS PLANES ANUALES DE TRABAJO DE LAS COMISIONES DE PREVENCIÓN, ATENCIÓN, SANSIÓN Y ERRADICACIÓN PARA DAR CUMPLIMIENTO AL PROGRAMA ESTATAL PARA PREVENIR, ATENDER, SANSIONAR Y ERRADICAR LA VIOLENCIA CONTRA LAS MUJERES, POR PARTE DE LA <b>ARQUITECTA FLOR DESSIRÉ LEÓN HERNÁNDEZ</b>, PRESIDENTA DEL INSTITUTO DE LA MUJER PARA EL ESTADO DE MORELOS Y SECRETARIA EJECUTIVA DEL SEPASE. <b> (8 MINS) </b>
                    <ul>
                        <li>
                            <a href="https://drive.google.com/file/d/1noqMnzX2lGWNcltZgA4ZHm3Erj2BxckR/view?usp=sharing" target="_blank">Plan Anual Prevención</a>
                        </li>
                        <li>
                            <a href="https://drive.google.com/file/d/1b4P9eItd8MP7KxM2ST3_h7Z9F-ma4wvx/view?usp=sharing" target="_blank">Plan Anual Erradicación</a>
                        </li>
                        <li>
                            <a href="https://drive.google.com/file/d/1jOYbQ4Ho5-8aJm45O4oZ2h6nJ5w433J4/view?usp=sharing" target="_blank">Plan Anual Sanción</a>
                        </li>
                        <li>
                            <a href="https://drive.google.com/file/d/1j64HE_p-4gJpqdjPY9TkUvPOBpWEaxOV/view?usp=sharing" target="_blank">Plan Anual Atención</a>
                        </li>
                    </ul>
                    
                </li>
                <li>PRESENTACIÓN DE LA RUTA PARA COMPILAR Y ENTREGAR INFORME DE SEGUIMIENTO AL MECANISMO DE ALERTA DE VIOLENCIA DE GÉNERO CONTRA LAS MUJERES, PERIODO COMPROMETIDO DEL 01 DE ENERO AL 31 DE DICIEMBRE DE 2020. (TOMA ACUERDO) POR PARTE DEL <b>LICENCIADO PABLO HÉCTOR OJEDA CÁRDENAS</b>, SECRETARIO DE GOBIERNO Y PRESIDENTE DEL SEPASE <b>(8 MINS)</b> <a href="https://drive.google.com/file/d/1ecB5JCykTqUJAk2xf7hew4gprQSEIqQM/view?usp=sharing" target="_blank">- Mostrar documento</a></li>
                <li>PRESENTACIÓN DE LOS SIGUIENTES INFORMES:
                    <ol type="a">
                        <li>INFORME ANUAL DEL SISTEMA ESTATAL PARA PREVENIR, ATENDER, SANCIONAR Y ERRADICAR LA VIOLENCIA CONTRA LAS MUJERES EN MORELOS, DEL AÑO 2020, POR PARTE DE LA <b>ARQUITECTA FLOR DESSIRÉ LEÓN HERNÁNDEZ</b>, PRESIDENTA DEL INSTITUTO DE LA MUJER PARA EL ESTADO DE MORELOS Y SECRETARIA EJECUTIVA DEL SEPASE <a href="https://drive.google.com/file/d/1kxQdsLJuVxFOe9XisECo8l-g6jKIbvQ7/view?usp=sharing" target="_blank">- Mostrar documento</a></li>
                        <li>INFORME DE LOS TRABAJOS DE LA MESA DE ARMONIZACIÓN LEGISLATIVA, DEL AÑO 2020, POR PARTE DE LA <b>ARQUITECTA FLOR DESSIRÉ LEÓN HERNÁNDEZ</b>, PRESIDENTA DEL INSTITUTO DE LA MUJER PARA EL ESTADO DE MORELOS Y SECRETARIA EJECUTIVA DEL SEPASE <b>(8 MINS)</b> <a href="https://drive.google.com/file/d/1KTDMYKDop00QqZSX_Ynnh2WgCdqCaACQ/view?usp=sharing" target="_blank">- Mostrar documento</a></li>
                    </ol>
                </li>
                <li>ASUNTOS GENERALES 
                    <ol type="A">
                        <li>PRESENTACIÓN DEL PORTARL <a href="https://contraloria.morelos.gob.mx/" target="_blink">contraloria.morelos.gob.mx</a>, ASÍ COMO DEL "MANUAL DE CONTRALORIA SOCIAL CON ENFOQUE EN BLINDAJE ELECTORAL 2021", POR PARTE DE LA <b>LICENCIADA ÁMERICA BERENICE JIMÉZ MOLINA</b>, SECRETARIA DE LA CONTRALORIA E INTEGRANTE DE LA SEPASE. <b>(8 MINS)</b></li>


                        <li>PRESENTACIÓN DE LOS RESULTADOS DEL CENSO DE POBLACIÓN Y VIVIENDA 2020, POR PARTE DEL <b>LICENCIADO RICARDO TORRES CARRETO</b>, COORDINADOR ESTATAL DEL INSTITUTO NACIONAL DE ESTADISTICAS Y GEOGRAFÍA, DELEGACIÓN MORELOS E INVITADO DEL SEPASE <b>(8 MINS)</b> <a href="https://drive.google.com/file/d/17RLeOfCNiZcsVnJZEt7b__c-9y9biVvj/view?usp=sharing" target="_blank">-Mostrar documento</a></li>
                    </ol>
                </li>
                <li>CLAUSURA DE LA SESIÓN POR PARTE DEL <b>LICENCIADO PABLO OJEDA CÁRDENAS</b>, SECRETARIO DE GOBIERNO <b>(2 MINS)</b></li>
            </ol>
            <div class="orden-fecha-duracion">
                <h4>FECHA 25 DE MARZO DE 2021</h4>
                <h4>DURACIÓN DEL EVENTO: 60 MINS</h4>
            </div>
             <div class="regresar-dashboard">
                <button> <router-link to="/dashboard">Regresar</router-link></button>
            </div>
        </div>
       
    </div>
</template>